// Generated by Framer (04e0834)

import { addFonts, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["TF_Vmzj5c"];

const serializationHash = "framer-dAsbW"

const variantClassNames = {TF_Vmzj5c: "framer-v-1cmd6jd"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "TF_Vmzj5c", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1cmd6jd", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"TF_Vmzj5c"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-38e83481-a340-4535-b223-ac61fd3243f4, rgb(255, 255, 255))", ...style}}><motion.div className={"framer-zjbjkx"} layoutDependency={layoutDependency} layoutId={"CUyKl7waU"} style={{backgroundColor: "rgb(0, 153, 255)", mask: "linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0,0,0,1) 100%) add", transformPerspective: 1200, WebkitMask: "linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0,0,0,1) 100%) add"}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-dAsbW.framer-1u2csiq, .framer-dAsbW .framer-1u2csiq { display: block; }", ".framer-dAsbW.framer-1cmd6jd { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 1px; justify-content: center; padding: 0px; position: relative; width: 22px; }", ".framer-dAsbW .framer-zjbjkx { flex: 1 0 0px; height: 1px; overflow: visible; position: relative; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-dAsbW.framer-1cmd6jd { gap: 0px; } .framer-dAsbW.framer-1cmd6jd > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-dAsbW.framer-1cmd6jd > :first-child { margin-top: 0px; } .framer-dAsbW.framer-1cmd6jd > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1
 * @framerIntrinsicWidth 30
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerDLvlQi_IE: React.ComponentType<Props> = withCSS(Component, css, "framer-dAsbW") as typeof Component;
export default FramerDLvlQi_IE;

FramerDLvlQi_IE.displayName = "Blogs / Hover Line";

FramerDLvlQi_IE.defaultProps = {height: 1, width: 30};

addFonts(FramerDLvlQi_IE, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})